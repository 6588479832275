import AOS from 'aos'
import dynamic from 'next/dynamic'
import { FC, useEffect } from 'react'
// import HomeBanner from 'components/HomePage/HomeBanner'
// import HomeSection from 'components/HomePage/HomeSection'
// import OurClients from 'components/HomePage/OurClients'
import BreadcrumbList from 'components/StructuredData'
import OurclientsBlock from './OurclientsBlock'
// import HomeBannerNew from './HomeBannerNew'
import HomeBannerAnimate from './HomeBannerAnimate'
// import HomeBannerFestival from './Festival'

// const OurClientsInner = dynamic(
//   () => import('components/ServicePage/OurClientsInner'),
//   {
//     ssr: false,
//   }
// )
const Services = dynamic(() => import('components/HomePage/Services'), {
  ssr: false,
})

const OurWork = dynamic(() => import('components/HomePage/OurWork'), {
  ssr: false,
})

const BetterFacility = dynamic(
  () => import('components/HomePage/BetterFacility'),
  {
    ssr: false,
  }
)

const Accolades = dynamic(() => import('components/HomePage/Accolades'), {
  ssr: false,
})
// const KeyFigures = dynamic(() => import('components/HomePage/KeyFigures'), {
//   ssr: false,
// })
const KeyFiguresNew = dynamic(
  () => import('components/HomePage/KeyFiguresNew'),
  {
    ssr: false,
  }
)
const OurProcess = dynamic(() => import('components/HomePage/OurProcess'), {
  ssr: false,
})
const Technologies = dynamic(() => import('components/HomePage/Technologies'), {
  ssr: false,
})
const HireDevelopers = dynamic(
  () => import('components/HomePage/HireDevelopersSection'),
  {
    ssr: false,
  }
)
const IndustriesWeServed = dynamic(
  () => import('components/HomePage/IndustriesWeServed'),
  {
    ssr: false,
  }
)

const ContactInformation = dynamic(
  () => import('components/HomePage/ContactInformation'),
  {
    ssr: false,
  }
)

const Testimonials = dynamic(() => import('components/HomePage/Testimonials'), {
  ssr: false,
})

// const OurClients = dynamic(() => import('components/HomePage/OurClients'))

const HomePage: FC = () => {
  // function fingerAnimation() {
  //   const paths = document.querySelectorAll<SVGPathElement>('.cls-1')
  //   const homeSec = document.querySelector<HTMLElement>('.home_banner')
  //   const clientHeight = homeSec?.clientHeight || 1
  //   Object.entries(paths).forEach(([key, value]) => {
  //     const length = value.getTotalLength()
  //     // eslint-disable-next-line no-param-reassign
  //     value.style.strokeDasharray = length.toString(10)
  //     // eslint-disable-next-line no-param-reassign
  //     value.style.strokeDashoffset = length.toString(10)
  //     window.addEventListener('scroll', () => {
  //       const scrollPercent =
  //         (document.body.scrollTop + document.documentElement.scrollTop) /
  //         clientHeight
  //       const draw = length * scrollPercent
  //       // eslint-disable-next-line no-param-reassign
  //       value.style.strokeDashoffset = (length - draw).toString(10)
  //     })
  //   })
  // }
  useEffect(() => {
    AOS.init()
    setTimeout(() => {
      AOS.refresh()
    }, 10)
    // fingerAnimation()
  }, [])

  return (
    <>
      <BreadcrumbList nameH1="Home" />
      <HomeBannerAnimate />
      {/* <HomeBannerNew /> */}
      {/* <HomeBanner /> */}
      {/* <HomeBannerFestival /> */}
      <OurclientsBlock />
      {/* <OurClientsInner /> */}
      {/* <HomeSection /> */}
      <Services />
      <Technologies />
      <div data-aos="fade-up">
        <HireDevelopers
          className=""
          data={{
            title: 'Hire Dedicated ',
            bluetitle: 'Developers',
            // img: '/assets/images/hire-dedicated-developers.jpg',
            desc: 'We will bring the expertise and specialization your business needs to build and perform with ease in a competitive environment. From using the best Blockchain, Frontend, Backend, Web, Mobility, Data Analytics, UI/UX technologies, and practices, we make your solutions click and engagement worthy.',
          }}
        />
      </div>
      <OurWork />
      {/* <KeyFigures /> */}
      <KeyFiguresNew />
      <OurProcess />
      <IndustriesWeServed
        data={{
          title: 'Industries We Serve',
          desc: 'Every industry has a unique customer base and users. As the leading mobile app development company, we provide bespoke solutions for every industry while catering to its specific needs in terms of technologies, design, and scalability.',
        }}
      />
      <Testimonials
        data={{
          title: 'Our customers love what we do',
          text: [
            'Our success is directly proportional to our client’s satisfaction. With an overall experience of more than a decade in this domain, we have built a strong trust and global client base by providing quality services.',
            'Our clients trust us as the top-notch Web and Mobile App Development Company. Hear it straight from our clients.',
          ],
        }}
      />
      <Accolades />
      <BetterFacility />
      {/* <OurClients /> */}
      <ContactInformation
        data={{
          title: 'Regional Contact Info',
          subTitle: 'We can give you the Wings to Fly. Contact us now!',
        }}
      />
    </>
  )
}

export default HomePage
