'use client'

import { ReactElement } from 'react'
import { usePathname } from 'next/navigation'
import { siteUrl } from 'services/config'

interface BreadcrumbListProps {
  nameH1?: string
}

function upperItem(itemName: string) {
  const words = itemName.split(' ')
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1)
  }
  return words.join(' ')
}

const BreadcrumbList = ({ nameH1 }: BreadcrumbListProps): ReactElement => {
  const pathname = usePathname()

  const hostname = siteUrl

  const pathSegments = pathname.split('/').filter((segment) => segment !== '')
  const breadcrumbs = pathSegments.map((segment, index) => ({
    name: segment.charAt(0).toUpperCase() + segment.slice(1), // Capitalize the first letter
    url: `${hostname}/${pathSegments.slice(0, index + 1).join('/')}`,
  }))
  let breadcrumbJson = {}
  if (breadcrumbs.length > 0) {
    breadcrumbJson = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Home',
          item: hostname,
        },
        breadcrumbs.map((item, index) => ({
          '@type': 'ListItem',
          position: index + 2,
          name:
            (breadcrumbs.length === 1 && index === 0) ||
            (breadcrumbs.length > 1 && index === 1)
              ? nameH1
              : upperItem(item.name.replaceAll('-', ' ')),
          item: item.url,
        })),
      ],
    }
  } else {
    breadcrumbJson = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Home',
          item: hostname,
        },
      ],
    }
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbJson) }}
    />
  )
}
export default BreadcrumbList
