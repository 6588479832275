import HomePage from 'components/HomePage'
import PageWrapper from 'components/PageWrapper'
import PAGE_CONSTANT_TEXTS from 'constants/pageMetaTags'

function Home() {
  const structuredDataLocation = {
    '@context': 'http://schema.org',
    '@type': ['ProfessionalService', 'Organization'],
    name: 'WebClues Infotech',
    url: 'https://www.webcluesinfotech.com',
    email: 'sales@webcluesinfotech.com',
    telephone: '+1-(978)-309-9910',
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '10:00',
        closes: '19:00',
      },
    ],
    address: [
      {
        '@type': 'PostalAddress',
        streetAddress: '513 Baldwin Ave, Jersey City, NJ 07306, USA',
        addressRegion: 'NJ',
        postalCode: '07306',
        addressCountry: 'US',
      },
    ],
    sameAs: [
      'https://www.facebook.com/Webcluesinfotechllp/',
      'https://www.linkedin.com/company/webclues-infotech',
      'https://www.instagram.com/webcluesinfotech/',
      'https://twitter.com/Webcluesinfo',
      'https://in.pinterest.com/webcluesinfotech/',
      'https://www.youtube.com/channel/UCtrVg4ZXL5fNv9E3E6Ufrxg/',
    ],
    contactPoint: [
      {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        email: 'sales@webcluesinfotech.com',
        url: 'https://www.webcluesinfotech.com/',
      },
    ],
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.1',
      bestRating: '5',
      worstRating: '1',
      reviewCount: '108',
    },
  }

  return (
    <PageWrapper {...PAGE_CONSTANT_TEXTS.home}>
      <script
        key="structured-data"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredDataLocation),
        }}
      />
      <HomePage />
    </PageWrapper>
  )
}

export default Home
