import React, { FC } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Image from 'next/image'
import Slider from 'react-slick'

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 2500,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
        // slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        // slidesToScroll: 2,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const OurclientsBlock: FC = () => {
  return (
    <section className="our_clients_inner homePage_slider our_clients_home_new py-2 d-flex flex-column justify-content-center">
      <Container fluid>
        <Row>
          <Col lg={24} xl={24} className="px-0">
            <Slider {...settings}>
              <div className="oc_inner_item text-center">
                <div className="item_img d-flex justify-content-center align-items-center mx-auto">
                  <Image
                    src="/assets/images/client-white-img-1.png"
                    alt="our-client-img-1"
                    width={194}
                    height={60}
                    quality={75}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <div className="oc_inner_item text-center">
                <div className="item_img d-flex justify-content-center align-items-center mx-auto">
                  <Image
                    src="/assets/images/client-white-img-2.png"
                    alt="our-client-img-2"
                    width={146}
                    height={60}
                    quality={75}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <div className="oc_inner_item text-center">
                <div className="item_img d-flex justify-content-center align-items-center mx-auto">
                  <Image
                    src="/assets/images/client-white-img-3.png"
                    alt="our-client-img-3"
                    width={146}
                    height={60}
                    quality={75}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <div className="oc_inner_item text-center">
                <div className="item_img d-flex justify-content-center align-items-center mx-auto">
                  <Image
                    src="/assets/images/client-white-img-4.png"
                    alt="our-client-img-4"
                    width={120}
                    height={60}
                    quality={75}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <div className="oc_inner_item text-center">
                <div className="item_img d-flex justify-content-center align-items-center mx-auto">
                  <Image
                    src="/assets/images/client-white-img-5.png"
                    alt="our-client-img-5"
                    width={173}
                    height={60}
                    quality={75}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <div className="oc_inner_item text-center">
                <div className="item_img d-flex justify-content-center align-items-center mx-auto">
                  <Image
                    src="/assets/images/client-white-img-6.png"
                    alt="our-client-img-6"
                    width={54}
                    height={60}
                    quality={75}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <div className="oc_inner_item text-center">
                <div className="item_img d-flex justify-content-center align-items-center mx-auto">
                  <Image
                    src="/assets/images/client-white-img-1.png"
                    alt="our-client-img-1"
                    width={194}
                    height={60}
                    quality={75}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <div className="oc_inner_item text-center">
                <div className="item_img d-flex justify-content-center align-items-center mx-auto">
                  <Image
                    src="/assets/images/client-white-img-2.png"
                    alt="our-client-img-2"
                    width={146}
                    height={60}
                    quality={75}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <div className="oc_inner_item text-center">
                <div className="item_img d-flex justify-content-center align-items-center mx-auto">
                  <Image
                    src="/assets/images/client-white-img-3.png"
                    alt="our-client-img-3"
                    width={146}
                    height={60}
                    quality={75}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <div className="oc_inner_item text-center">
                <div className="item_img d-flex justify-content-center align-items-center mx-auto">
                  <Image
                    src="/assets/images/client-white-img-4.png"
                    alt="our-client-img-4"
                    width={120}
                    height={60}
                    quality={75}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <div className="oc_inner_item text-center">
                <div className="item_img d-flex justify-content-center align-items-center mx-auto">
                  <Image
                    src="/assets/images/client-white-img-5.png"
                    alt="our-client-img-5"
                    width={173}
                    height={60}
                    quality={75}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <div className="oc_inner_item text-center">
                <div className="item_img d-flex justify-content-center align-items-center mx-auto">
                  <Image
                    src="/assets/images/client-white-img-6.png"
                    alt="our-client-img-6"
                    width={54}
                    height={60}
                    quality={75}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default OurclientsBlock
