// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
'use client'
import { FC, useRef } from 'react'
import ReactTypingEffect from 'react-typing-effect'
import { motion, useAnimation } from 'framer-motion'
import { useEffect, useState } from 'react'
import useMediaQuery from 'scripts/useMobileView'
import Image from 'next/image'
import ModalVideo from 'react-modal-video'

const HomeBannerAnimate: FC = () => {
  // const [isMobile, setIsMobile] = useState(false)
  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth < 1200)
  //   }
  //   handleResize()
  //   window.addEventListener('resize', handleResize)
  //   return () => window.removeEventListener('resize', handleResize)
  // }, [])

  const [isOpen, setOpen] = useState(false)
  const vidRef = useRef()
  const controls = useAnimation()
  const isMobile = useMediaQuery('(max-width: 575px)')
  const isTablet = useMediaQuery('(min-width: 576px) and (max-width: 1024px)')
  const isDesktop = useMediaQuery('(min-width: 1025px)')

  useEffect(() => {
    controls.start('moveAndRotate').then(() => {
      controls.start('expand')
    })
  }, [controls])

  const variants = {
    moveAndRotate: {
      x: '-50%',
      rotate: 720,
      transition: { delay: 1.5, duration: 1.7 },
    },
    expand: {
      width: '100%',
      height: '100%',
      borderRadius: 0,
      bottom: 0,
      transition: { delay: 0.5, duration: 0.5 },
    },
  }

  const [videoSrc, setVideoSrc] = useState<any>('')

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      if (width < 600) {
        setVideoSrc('/assets/images/home-page/mobile.mp4')
      } else if (width < 1200) {
        setVideoSrc('/assets/images/home-page/tablet.mp4')
      } else {
        setVideoSrc('/assets/images/home-page/popup.mp4')
      }
    }

    handleResize()
    if (vidRef.current) {
      vidRef.current?.load()
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [videoSrc])

  return (
    <section className="videoMainBanner">
      <div className="videoMainBannerInner px-3">
        <motion.div
          className="videoMainBannerPara"
          initial={{ opacity: '0', y: '100px' }}
          animate={{ opacity: '1', y: '0' }}
          transition={{
            type: 'spring',
            delay: 4.5,
            duration: 0.5,
            stiffness: 100,
            damping: 10,
          }}
        >
          <h1 className="mb-5" data-aos="fade-up" data-aos-delay="200">
            <span>Empowering Businesses With</span>
            <ReactTypingEffect
              text={[
                'Digital Transformation',
                'Technology Consulting',
                'Intelligence Automation',
              ]}
              speed={50}
              eraseSpeed={30}
              className="typing_text"
            />
          </h1>

          <p
            className="text_para text_para_large text_para_home text-white text-lg"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            We integrate cutting-edge technology into your business processes to
            propel your enterprise to unprecedented heights on a global scale.
          </p>
        </motion.div>
        <motion.img
          onClick={() => setOpen(true)}
          className="videoMainBannerPara"
          initial={{ opacity: '0', y: '-100px' }}
          animate={{ opacity: '1', y: '0' }}
          transition={{
            type: 'spring',
            delay: 4.5,
            duration: 0.5,
            stiffness: 100,
            damping: 10,
          }}
          className="openVideo"
          src="assets/images/play-video.svg"
          alt="Binance"
        ></motion.img>
      </div>
      <motion.div
        initial={{ x: '-100vw', rotate: 0 }} // Initial size of the circle
        animate={controls}
        variants={variants}
        className="videoMainBannerCircle circlevideo"
      >
        <video autoPlay loop muted preload="none" ref={vidRef}>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <motion.img
          initial={{ opacity: '1' }}
          animate={{ opacity: '0' }}
          transition={{ delay: 3.2, duration: 0.5 }}
          src="/assets/images/banner-img01.png"
          alt="Binance"
        ></motion.img>
      </motion.div>
      <motion.div
        initial={{ x: '-100vw', rotate: 0 }}
        animate={{ x: '-50%', rotate: 720 }}
        transition={{ delay: 1, duration: 1.5 }}
        className="videoMainBannerCircle circleBlue"
      >
        <img src="assets/images/banner-img02.png" alt="Binance" />
      </motion.div>

      <motion.div
        initial={{ x: '100vw', rotate: 0 }}
        animate={{ x: '-50%', rotate: -720 }}
        transition={{ delay: 1.2, duration: 1.5 }}
        className="videoMainBannerCircle circleWhite"
      >
        <img src="/assets/images/banner-img03.png" alt="Binance" />
      </motion.div>

      {/* <Container>
        <div className="align-items-center row">
          <div className="col-xl-7">
            <TitleH1 text="Empowering Businesses Globally With">
              <ReactTypingEffect
                text={[
                  'Digital Transformation',
                  'Technology Consulting',
                  'Intelligence Automation',
                ]}
                speed={50}
                eraseSpeed={30}
                className="typing_text"
              />
            </TitleH1>

            <p
              className="text_para text_para_large text_para_home mb-5 text-white text-lg"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              We integrate cutting-edge technology into your business processes
              to propel your enterprise to unprecedented heights on a global
              scale.
            </p>
            <LinkButton
              path="/contact-us"
              className="text-decoration-none"
              tabIndex={0}
              dataAos="fade-up"
              dataAosDelay="400"
            >
              <span>Get in Touch</span>
            </LinkButton>
          </div>
        </div>
      </Container> </section>
      {isMobile ? (
    <>
     <BannerSlider />
     <BannerSliderBot />
    </>
    ) : (
      <div className="mainCircleOut">
        <div className="mainCircle">
          <div className="big-circle">
            <div className="icon-block">
              <Image
                src="/assets/images/cr01.svg"
                alt="Binance"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
            <div className="icon-block">
              <Image
                src="/assets/images/cr02.svg"
                alt="Binance"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
            <div className="icon-block">
              <Image
                src="/assets/images/cr03.svg"
                alt="Javascript"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
            <div className="icon-block">
              <Image
                src="/assets/images/cr04.svg"
                alt="Opencart"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
            <div className="icon-block">
              <Image
                src="/assets/images/cr05.svg"
                alt="React"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
          </div>
          <div className="circle">
            <div className="icon-block">
              <Image
                src="/assets/images/cr06.svg"
                alt="Python"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
            <div className="icon-block">
              <Image
                src="/assets/images/cr07.svg"
                alt="Swift"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
            <div className="icon-block">
              <Image
                src="/assets/images/cr08.svg"
                alt="Shopify"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
            <div className="icon-block">
              <Image
                src="/assets/images/cr09.svg"
                alt="Shopify"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
          </div>
          <div className="center-logo">
            <Image
              src="/assets/images/cr_logo.svg"
              alt="logo"
              width={259}
              height={197}
            />
          </div>
        </div>
      </div>
       )} */}

      {isOpen && (
        <ModalVideo
          channel="custom"
          isOpen={isOpen}
          url="/assets/images/home-page/main-banner.mp4"
          onClose={() => setOpen(false)}
          autoplay
        >
          <video
            autoPlay
            loop
            preload="none"
            height="100%"
            style={{ position: 'absolute' }}
          >
            <source src="/assets/images/home-page/popup.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </ModalVideo>
      )}
    </section>
  )
}

export default HomeBannerAnimate
