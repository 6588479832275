import { useState, useEffect } from 'react'

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia(query)
    const handleChange = () => setMatches(mediaQuery.matches)

    // Set initial state
    handleChange()

    // Listen for changes
    mediaQuery.addEventListener('change', handleChange)

    // Clean up event listener on unmount
    return () => mediaQuery.removeEventListener('change', handleChange)
  }, [query])

  return matches
}

export default useMediaQuery
